<template>
    <v-container class="mt-0 pt-0 users-to-evaluate">
        <h2
            v-if="postInfo.title"
            class="title-post"
            v-text="postInfo.title"
        />
        <div
            v-if="postInfo.image"
            class="d-flex justify-center"
        >
            <img
                :src="postInfo.image"
                height="100px"
            />
        </div>
        <div
            v-if="postInfo.body"
            class="pb-4"
        >
            <span
                class="ql-editor pa-0"
                v-html="postInfo.body"
            />
        </div>

        <div class="users-to-evaluate__tools">
            <div v-if="!this.$vuetify.breakpoint.mobile" />

            <v-text-field
                v-model="filters.search"
                :label="$t('evaluation.searchPerson')"
                prepend-inner-icon="mdi-magnify"
                clearable
            ></v-text-field>
        </div>

        <div class="mt-6">
            <h4
                class="text-subtitle--underlined mb-3"
                v-html="$t('evaluation.peoplePendingEvaluation')"
            />

            <ol>
                <li
                    v-for="user in orderedGroupsUsers.notEvaluated"
                    class="pb-1"
                >
                    <span
                        v-text="getUserDisplayText(user)"
                        class="cursor-pointer"
                        @click="selectItemConfirmed(user)"
                    />
                </li>
            </ol>
        </div>

        <div class="mt-8">
            <h4
                class="text-subtitle--underlined mb-3"
                v-html="$t('evaluation.peopleEvaluated')"
            />

            <ol>
                <li v-for="user in orderedGroupsUsers.evaluated">
                    <div class="d-flex justify-space-between align-center">
                        <span
                            v-text="getUserDisplayText(user)"
                            class="cursor-pointer"
                            @click="selectItem(user)"
                        />
                        <div>
                            <v-btn
                                icon
                                @click="selectItem(user)"
                                small
                                color="primary"
                            >
                                <v-icon small>mdi-pencil</v-icon></v-btn
                            >
                            <!-- <v-btn
                                icon
                                @click="deleteItem(user)"
                                small
                            >
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn> -->
                        </div>
                    </div>
                </li>
            </ol>
        </div>

        <confirm-dialog ref="confirm" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from '@/plugins/axios';
import dateTimeMixin from '@/mixins/dateTimeMixin';
import EvaluationsAPIService from '@/services/EvaluationsAPIService';
import itemPointsMixin from '@/mixins/itemPointsMixin';

const EVALUATION_TYPES = ['selfAppraisal', 'responsible', 'collaborators', 'pairs'];

// Set the first order to sort the persons, seconds is alphabetically
const SORT_ORDER = {
    selfAppraisal: 1,
    responsible: 2,
    collaborators: 3,
    pairs: 4
};

export default {
    name: 'UsersToEvaluate',
    mixins: [dateTimeMixin, itemPointsMixin],
    data: function () {
        return {
            usersData: {
                allUsers: [],
                pairs: [],
                collaborators: [],
                responsible: [],
                selfAppraisal: []
            },
            evaluating: {
                list: [],
                mapUsersEvaluating: {}
            },
            filters: {
                search: ''
            }
        };
    },
    async created() {
        this.$store.dispatch('loading/start');

        this.itemsPerPage = this.$store.state?.cache?.persistentInfo?.rankingItemsPerPage ?? 50;

        await this.loadUsersToEvaluate();

        await this.loadEvaluatedUsersInfo();

        this.$store.dispatch('loading/end');

        this.$watch('itemsPerPage', async value => {
            const key = 'rankingItemsPerPage';
            await this.$store.dispatch('cache/getAndUpdatePersistentInfo', {
                key,
                value
            });
        });
    },
    methods: {
        itemRowBackground: function (item) {
            return item.id === this.getUserId ? 'table-current-user' : '';
        },

        async loadUsersToEvaluate() {
            try {
                const data = await EvaluationsAPIService.getUsersToEvaluate();

                this.initialize(data.data);
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }
        },

        async loadEvaluatedUsersInfo() {
            try {
                const data = await EvaluationsAPIService.getEvaluatingInfo();

                this.evaluating.list = data.data.evaluatingItems;

                const map = {};
                this.evaluating.list.forEach(item => {
                    map[item.evaluated_users_id] = item.finished_at ? 2 : 1;
                });
                this.evaluating.mapUsersEvaluating = map;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }
        },

        initialize(data) {
            try {
                this.usersData = { ...data, allUsers: this.getUnifiedUsers(data) };
            } catch (error) {
                console.log('error', error);
            }
        },

        getUnifiedUsers(usersData) {
            const users = [];
            const mapExistingUsers = {};

            for (const key of EVALUATION_TYPES) {
                let usersAdding = usersData?.[key] ?? [];
                usersAdding = usersAdding
                    .filter(user => {
                        const existUser = mapExistingUsers[user.id];

                        if (!existUser) {
                            mapExistingUsers[user.id] = true;
                        }

                        return !existUser;
                    })
                    .map(user => ({ ...user, evaluationType: key }));

                users.push(...usersAdding);
            }

            return users;
        },

        getUserDisplayText(item) {
            const evaluationType = this.$t(`evaluation.filter.${item.evaluationType}`);

            return `${(item?.name ?? '').trim()} ${(item?.lastname ?? '').trim()} (${evaluationType})`;
        },

        async selectItem(item) {
            const personName = `${(item?.name ?? '').trim()} ${(item?.lastname ?? '').trim()}`.trim();

            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('evaluation.editMessageConfirm', { person: personName }),
                    { useYesNoTextButton: true }
                )
            ) {
                this.selectItemConfirmed(item);
            }
        },

        async selectItemConfirmed(item) {
            this.$router.push({ name: 'evaluate-user', params: { game: 51, user: item.id } }); // Load the gameId for company
        },

        async deleteItemConfirmed(item) {
            const itemId = item.id;

            try {
                const result = await axios.delete(`games/evaluations/${itemId}`);

                if (result?.status === 200 || result?.status === 204) {
                    this.evaluating.mapUsersEvaluating[itemId] = null;
                    this.evaluating.list = this.evaluating.list.filter(item => item.evaluated_users_id != itemId);

                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'));
                }
            } catch (error) {
                console.log('error', error);
                this.$store.dispatch('snackbar/error', this.$t('common.accionError'));
            }
            this.$store.dispatch('loading/end');
        },

        async deleteItem(item) {
            if (await this.$refs.confirm.open(this.$t('common.confirmTitle'), this.$t('common.confirmMSGDelete'))) {
                this.deleteItemConfirmed(item);
            }
        }
    },
    computed: {
        ...mapGetters(['getUser', 'getUserId']),
        ...mapGetters('languages', ['getLang']),
        ...mapGetters('cache', ['getPosts']),

        postInfo() {
            const url = 'evaluate/users';
            let currentPost = this.getPosts.find(post => post.attributes.url === url);
            if (!currentPost) {
                currentPost = {
                    attributes: {
                        title2: '',
                        body: '',
                        image2: ''
                    }
                };
            }

            const { title2: title, body, image2 } = currentPost.attributes;
            const image = image2
                ? `${process.env.VUE_APP_API_ENDPOINT}media/imagesSimple?filename=posts/images/${image2}`
                : '';

            return { title, body, image };
        },

        filteredUsers() {
            let users = this.usersData.allUsers;
            const search = this.filters.search;

            return !search ? users : users.filter(user => this.getUserDisplayText(user).toLowerCase().includes(search));
        },

        /**
         * @return {evaluated: [], notEvaluated: []} - users
         */
        orderedGroupsUsers() {
            let users = this.filteredUsers;

            users = users.sort((userA, userB) => {
                const userAOrderType = SORT_ORDER[userA.evaluationType];
                const userBOrderType = SORT_ORDER[userB.evaluationType];

                if (userAOrderType > userBOrderType) return 1;
                if (userAOrderType < userBOrderType) return -1;

                return userA.name.localeCompare(userB.name);
            });

            const { mapUsersEvaluating } = this.evaluating;

            const evaluated = [];
            const notEvaluated = [];

            users.forEach(user => {
                if (mapUsersEvaluating[user.id] === 2) {
                    evaluated.push(user);
                } else {
                    notEvaluated.push(user);
                }
            });

            return { evaluated, notEvaluated };
        }
    },
    destroyed() {}
};
</script>

<style lang="scss" scope>
.users-to-evaluate {
    &__tools {
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        column-gap: 4rem;
        flex-wrap: wrap;

        & > div {
            max-width: 20rem;
        }
    }
}

.theme--light.v-text-field > .v-input__control > .v-input__slot::before {
    border-color: var(--v-primary-base, rgba(0, 0, 0, 0.42));
}
.v-text-field > .v-input__control > .v-input__slot::before {
    border-color: inherit;
    border-style: solid;
    border-width: thin 0 0 0;
}
</style>
